/* Category card for desktop (unchanged) */
.category-card-category-card { 
  flex: 0 0 auto;
  cursor: pointer;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  justify-content: center; /* Center content */
  flex-direction: column;
  text-align: center;
  border-radius: 15px; /* Slightly curved edges */
  box-shadow: 10px 8px 16px rgba(114, 2, 2, 0.1);
  background-color: rgba(171, 176, 178, 0.264);
  margin-bottom: 10px;
}

/* Hover effect for desktop */
.category-card-category-card:hover {
  color: var(--dl-color-pallet-yellow);
  transform: scale(1.1); /* Slight hover effect */
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2); /* Shadow increases on hover */
}

/* Category card image (desktop view - unchanged) */
.category-card-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
  object-position: bottom;
  border-radius: 10px; /* Rounded corners for the image */
}

/* Category card text styling (unchanged) */
.category-card-text {
  line-height: 14px;
  font-size: 16px;
  margin-bottom: 10px;
}

/* For mobile screens (below 767px) */
@media (max-width: 767px) {
  .category-card-category-card {
    width: 100%; /* Full width for mobile */
    margin-bottom: 20px; /* Add space between cards */
    border-radius: 12px; /* Curved edges for mobile */
    box-shadow: 10px 8px 16px rgba(114, 2, 2, 0.1);
    background-color: rgba(171, 176, 178, 0.264); /* Adjusted shadow for mobile */
    margin-bottom: 10px;
  }

  .category-card-image {
    width: 100%;
    height: auto; /* Maintain aspect ratio and prevent cropping */
    max-height: 250px; /* Limit max height to 250px for mobile */
    object-fit: contain; /* Prevent cropping */
    border-radius: 10px; /* Rounded corners for the image */
  }

  .category-card-text {
    font-size: 25px; /* Adjust text size for smaller screens */
    text-align: center; /* Ensure text is centered */
    margin-bottom: 10px;
  }
}
