.section-heading-section-heading {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .section-heading-text1 {
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
  }
  .section-heading-text2 {
    color: var(--dl-color-gray-black60);
    text-align: center;
    line-height: 24px;
  }
  
  