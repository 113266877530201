.item-card-gallery-card {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px; /* Curved edges */
  box-shadow: 10px 8px 16px rgba(114, 2, 2, 0.1); /* Subtle shadow */
  overflow: hidden; /* Ensures the content stays within the curved edges */
}

.item-card-image {
  top: 0px;
  flex: 1;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  object-fit: cover;
  border-radius: 15px 15px 0 0; /* Curved edges only at the top */
}

.item-card-container1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff; /* Ensure the content background is white */
  border-radius: 0 0 15px 15px; /* Curved edges only at the bottom */
}

.item-card-text {
  color: var(--dl-color-gray-black80);
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: var(--dl-space-space-unit);
}

.item-card-container2 {
  display: grid;
  grid-gap: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: repeat(5, 1fr);
}

.item-card-icon10, 
.item-card-icon12, 
.item-card-icon14, 
.item-card-icon16, 
.item-card-icon18 {
  fill: var(--dl-color-pallet-yellow);
  width: 24px;
  height: 24px;
}

.item-card-container3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.item-card-currency, 
.item-card-value {
  color: var(--dl-color-gray-black50);
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
}

.item-cardroot-class-name {
  flex: 1;
}

.item-cardroot-class-name4 {
  flex: 1;
  height: 100%;
}

@media(max-width: 767px) {
  .item-card-gallery-card {
    flex-direction: column;
  }
}

@media(max-width: 479px) {
  .item-card-image {
    top: 0px;
    left: 0px;
    right: auto;
    bottom: auto;
  }
}
