:root {
    --dl-color-gray-500: #595959;
    --dl-color-gray-700: #999999;
    --dl-color-gray-900: #D9D9D9;
    --dl-color-gray-950: #e5e5e5ff;
    --dl-size-size-large: 144px;
    --dl-size-size-small: 48px;
    --dl-color-gray-black: #282828;
    --dl-color-gray-white: #ffffff;
    --dl-size-size-medium: 96px;
    --dl-size-size-xlarge: 192px;
    --dl-size-size-xsmall: 16px;
    --dl-space-space-unit: 16px;
    --dl-size-size-xxlarge: 288px;
    --dl-color-gray-black50: #00000080;
    --dl-color-gray-black60: #00000099;
    --dl-color-gray-black80: #4c4b4b;
    --dl-color-pallet-green: rgba(146, 184, 184, 0.556);
    --dl-size-size-maxwidth: 1328px;
    --dl-color-pallet-yellow: rgba(182, 176, 180, 0.417);
    --dl-radius-radius-round: 50%;
    --dl-space-space-halfunit: 8px;
    --dl-space-space-sixunits: 96px;
    --dl-space-space-twounits: 32px;
    --dl-radius-radius-radius2: 2px;
    --dl-radius-radius-radius4: 4px;
    --dl-radius-radius-radius8: 8px;
    --dl-space-space-fiveunits: 80px;
    --dl-space-space-fourunits: 64px;
    --dl-space-space-threeunits: 48px;
    --dl-space-space-oneandhalfunits: 24px;
  }
  .button {
    color: var(--dl-color-gray-black80);
    cursor: pointer;
    display: flex;
    padding: 0.5rem 1rem;
    position: relative;
    box-sizing: border-box;
    font-style: normal;
    transition: 0.3s;
    align-items: flex-start;
    flex-shrink: 1;
    font-weight: 500;
    line-height: 14px;
    padding-top: 12px;
    border-color: var(--dl-color-gray-black50);
    border-style: solid;
    border-width: 1px;
    padding-left: 24px;
    border-radius: 0px;
    padding-right: 24px;
    padding-bottom: 12px;
    text-decoration: none;
  }
  .button:hover {
    color: var(--dl-color-gray-white);
    border-color: var(--dl-color-pallet-yellow);
    background-color: var(--dl-color-pallet-yellow);
  }
  .input {
    color: var(--dl-color-gray-white);
    cursor: auto;
    outline: none;
    padding: 0.5rem 1rem;
    background-color: var(--dl-color-pallet-green);
  }
  .textarea {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }
  .list {
    width: 100%;
    margin: 1em 0px 1em 0px;
    display: block;
    padding: 0px 0px 0px 1.5rem;
    list-style-type: none;
    list-style-position: outside;
  }
  .list-item {
    display: list-item;
  }
  .teleport-show {
    transform: translateX(0%) !important;
  }
  .section-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-fiveunits);
    justify-content: center;
  }
  .max-width-container {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    margin-top: 0px;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .navbar-link {
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    height: auto;
    font-size: 13px;
    text-align: left;
    transition: 0.3s;
    font-family: Jost;
    font-weight: 500;
    text-decoration: none;
    padding: 2px;
  }
  .navbar-link:hover {
    color: var(--dl-color-pallet-yellow);
  }
  .navbar-logo-title {
    color: var(--dl-color-gray-black);
    cursor: pointer;
    height: auto;
    font-size: 24px;
    transition: 0.3s;
    font-family: Jost;
    font-weight: 500;
    line-height: 20px;
    margin-left: var(--dl-space-space-fourunits);
    margin-right: var(--dl-space-space-fourunits);
    letter-spacing: 0.3em;
    text-decoration: none;
  }
  .navbar-logo-title:hover {
    color: rgba(239, 193, 76, 1);
  }
  .Heading-3 {
    font-size: 24px;
    font-style: normal;
    font-family: Jost;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4em;
    text-transform: none;
    text-decoration: none;
  }
  .Content {
    font-size: 16px;
    font-family: Jost;
    font-weight: 400;
    letter-spacing: 0.02;
    text-transform: none;
    text-decoration: none;
  }
  .Heading-1 {
    font-size: 36px;
    font-family: Jost;
    font-weight: 500;
    line-height: 47px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    text-decoration: none;
  }
  .Heading-2 {
    font-size: 28px;
    font-family: Jost;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    text-decoration: none;
  }
  @media(max-width: 991px) {
    .navbar-logo-title {
      margin-left: 0px;
    }
  }
  @media(max-width: 767px) {
    .section-container {
      padding-top: var(--dl-space-space-twounits);
    }
    .max-width-container {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
  }
  
  