.home-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  padding-top: 70px;
  flex-direction: column;
}
.home-navbar1 {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  border: none;
  box-shadow: none;
  background-color: rgba(240, 240, 229, 0.718);
  /* background-color: rgba(114, 120, 118, 0.869); Subtle transparency */
}

.home-header {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  max-width: 1200px; /* Max width for the header container */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.home-navbar2 {
  width: 100%;
  display: flex;
  /* position: relative; */
  align-items: center;
  justify-content: space-around;
  margin-left: 0px;
  
}

.home-container11 {
  margin-left: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 120px;
}

.home-image1 {
  width: 24px;
  height: 24px;
  margin-left:0px;
  margin-right: 16px;
}

.home-textinput {
  padding: 5px; /* Smaller padding */
  border: none; /* No border */
  background-color: transparent; /* Keep background transparent */
  outline: none; /* Remove default outline on focus */
  width: 150px; /* Smaller width */
  margin-top: 5px; /* Space between the input and other elements */
}

.search-results {
  margin-top: 5px; /* Adjust margin to reduce space between input and results */
  list-style: none;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.959); /* Background for results */
  border: none; /* No border */
  border-radius: 0px 0px 25px 0px;
  box-shadow: 10px 12px 20px rgba(170, 26, 26, 0.149); /* Rounded corners */
  position: absolute; /* Position results below input */
  z-index: 10; /* Ensure results appear above other elements */
}

.search-results li {
  padding: 5px; /* Padding for each result */
}

.search-results li:hover {
  background-color: #d8d8d8; /* Highlight on hover */
}

.search-results a {
  text-decoration: none; /* Remove underline */
  color: #1f2122; /* Link color */
}

.home-middle1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 1;
}

.home-left1,
.home-right1 {
  display: flex;
  gap: 30px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: start;
  margin-right: 30px;
}

.navbar-link {
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
}

.navbar-link:hover {
  color: #888;
}

.navbar-logo-title {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.home-icons {
  display: flex;
  gap: 16px;
  align-items: center;
}

.home-image2,
.home-image3,
.home-image4 {
  width: 24px;
  height: 24px;
}

.home-burger-menu {
  display: none;
  cursor: pointer;
}

.home-mobile-menu {
  display: none;
}

.home-nav {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.home-close-mobile-menu {
  align-self: flex-end;
  cursor: pointer;
}

.home-middle2 {
  
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 1366px) {
  .home-navbar2 {
    display: none; /* Hide desktop navbar */
  }

  .home-burger-menu {
    display: block; /* Show burger menu */
  }

  .home-mobile-menu {
    display: flex; /* Display mobile menu */
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
    padding: 20px;
  }

  .home-middle1 {
    display: none; /* Hide middle1 section on smaller screens */
  }

  .home-middle2 {
    display: flex; /* Show middle2 section on smaller screens */
  }
}

@media screen and (max-width: 1366px) {
  .home-navbar2 {
    display: none;
  }

  .home-burger-menu {
    display: block;
  }

  .home-mobile-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
    padding: 20px;
  }

  .home-middle1 {
    display: none;
  }

  .home-middle2 {
    display: flex;
  }
}

.home-image2 {
  width: 24px;
  height: 24px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.home-image3 {
  width: 24px;
  height: 24px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.home-image4 {
  width: 24px;
  height: 24px;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.home-burger-menu {
  display: none;
}
.home-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container12 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo-center2 {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Jost;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-middle2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.home-text16 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text17 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text18 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text19 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text20 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-text21 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.home-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon18 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.home-hero1 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: var(--dl-color-pallet-green);
  border-bottom-left-radius: 0px; /* Bottom-left curved edge */
  border-bottom-right-radius: 25px; /* Bottom-right curved edge */
  box-shadow: 10px 12px 20px rgba(170, 26, 26, 0.1); /* Box shadow */
}
.home-max-width1 {
  max-width: 1032px;
}
.home-hero2 {
  margin-top: 0px ;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items:center;
  flex-direction: row;
  justify-content:space-between;
  height:100%;
}
.home-container13 {
  display: flex;
  margin-right: var(--dl-space-space-oneunits);
  padding-right: var(--dl-space-space-oneunits);
  flex-direction:column;
  column-gap: 50px;
}
.home-info {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-image5 {
  width: 2px;
  height: 52px;
  margin: 0 14px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);
}
.home-text22 {
  color: var(--dl-color-gray-black50);
  font-size: 12px;
  text-align: left;
  font-family: Jost;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
}
.home-text26 {
  max-width: 450px;
  margin-bottom: var(--dl-space-space-threeunits);
  letter-spacing: 0.2em;
}
.home-container14 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: center;
}
.home-text27 {
  color: var(--dl-color-gray-black80);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  /* margin-right: 10px; */
  margin-bottom: 4px; /* Adjusted for a bit less spacing */
  letter-spacing: 0.3em;
  
}

.home-text28 {
  color: var(--dl-color-gray-black80);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px ;
  display: block;
  margin-top: 2px;
}

.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-image6 {
  margin-left: 200px;
  width: 100%;
  position: relative;
  max-width: 579px;
  box-sizing: border-box;
  max-height: 579px;
  object-fit: cover;
  border-color: transparent;
  justify-content: right;
  align-items: self-end;
  justify-items: end;
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.5s ease;
  
}
@media (max-width: 768px) {
  .home-image6 {
    margin-left: 0; /* Remove left margin for mobile */
    margin: 0 auto; /* Center the image on mobile */
    max-width: 90%; /* Make sure the image fits within the mobile screen width */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Ensures proper centering */
  }
}

/* Media Query for Smaller Mobile Screens (below 480px) */
@media (max-width: 480px) {
  .home-image6 {
    max-width: 100%; /* Full width for smaller screens */
    margin: 0 auto; /* Center the image */
  }
}

.home-image6:hover  {
  transform: scale(1.2); /* Reset rotation on hover */
}
.image6-container::after {
content: '';
position: absolute;
bottom: 0;
left: 0;
right: 0;
height: 50%; /* Height of reflection */
background: linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
pointer-events: none; /* Allow interaction with the image below */
}
.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  flex-wrap: wrap;
  max-width: 1080px;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
}
.slide123{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.home-banner {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  /* margin-bottom: var(--dl-space-space-threeunits); */
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-950);
  margin-bottom: 100px;
}
.home-container15 {
  flex: 0 0 auto;
  width: 250px;
  height: 100px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-950);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-text29 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text30 {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 1em;
  text-transform: uppercase;
}
.home-container16 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 100vw;
  height: auto;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position: center;
  color: black;
  text-align: center;
  /* padding: 80px; */
}

.home-container17 {
  width: 100%; /* Full width of the parent */
  display: flex;
  position: relative; 
  max-width: auto; 
  box-sizing: border-box; 
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  flex-direction: column;
  background-color: rgba(240, 240, 229, 0.718);
}

/* Media query for smaller screens */
@media (max-width: auto) {
  .home-container17 {
       /* Smaller padding for mobile */
      max-width: 100%; /* Full width on mobile */
  }

  .home-text33 {
      font-size: 20px; /* Adjust font size for better readability */
  }
}

/* Additional styles for very small screens */
@media (max-width: 480px) {
  .home-container17 {
      padding: 0 10px; /* Even smaller padding for very small screens */
  }

  .home-text33 {
      font-size: 18px; /* Further reduce font size */
  }
}

.home-text33 {
  color: var(--dl-color-gray-black80);
  width: 100%;
  height: auto;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-family: Jost;
  font-weight:200px;
  line-height: 175%;
  margin-bottom: var(--dl-space-space-threeunits);
}

.home-svg {
  top: -30px;
  width: 199px;
  height: 200px;
  position: absolute;
}
.home-gallery {
  width: 100%;
  display: flex;
  align-items: stretch;
}
.home-left2 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-right2 {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.home-top {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.home-left3 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-right3 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-bottom {
  flex: 1;
  width: 100%;
  display: flex;
}
.home-left4 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-right4 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-full-width-banner {
  width: 100%;
  display: flex;
  align-items: stretch;
  padding-top: var(--dl-space-space-fourunits);
  justify-content: center;
}
.home-left5 {
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
  background-color: #F3F0E6;
}
.home-content {
  margin: 0 0 40px 0;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  flex-direction: column;
}
.home-text39 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  margin: 0 0 16px 0;
  font-size: 28px;
  align-self: auto;
  font-style: normal;
  text-align: left;
  font-family: Jost;
  font-weight: 500px;
  line-height: 20px;
  font-stretch: normal;
  text-decoration: none;
}
.home-text40 {
  color: rgba(0, 0, 0, 0.6000000238418579);
  width: 267px;
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: normal;
  text-align: center;
  font-family: Jost;
  font-weight: 400px;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-btn {
  cursor: pointer;
  display: flex;
  padding: 16px 24px;
  position: relative;
  box-sizing: border-box;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(239, 193, 76, 1);
}
.home-btn:hover {
  background-color: var(--dl-color-gray-white);
}
.home-text41 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  align-self: auto;
  font-style: normal;
  text-align: left;
  font-family: Jost;
  font-weight: 500px;
  line-height: 14px;
  font-stretch: normal;
  text-decoration: none;
}
.home-image7 {
  width: 60%;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-footer1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-950);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-footer2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.home-container19 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.home-text42 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text43 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.home-text46 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-twounits);
}
.home-text47 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.home-links-container {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container20 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text48 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.home-text49 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text50 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text51 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text52 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text53 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text54 {
  color: var(--dl-color-gray-black50);
}
.home-container21 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text55 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.home-text56 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text57 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text58 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text59 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text60 {
  color: var(--dl-color-gray-black50);
}
.home-container22 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text61 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.home-text62 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text63 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text64 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text65 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .home-container11 {
    display: none;
  }
  .home-left1 {
    display: none;
  }
  .home-right1 {
    display: none;
  }
  .home-icons {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-icon10 {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .home-logo-center2 {
    margin-left: 0px;
  }
  .home-hero2 {
    flex-direction: column;
  }
  .home-container13 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneunits);
    padding-right: 0px;
  }
  .home-text26 {
    text-align: center;
  }
  .home-left2 {
    width: 100%;
  }
  .home-footer2 {
    flex-direction: column-reverse;
  }
  .home-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-main {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: 5px;
  }
  .home-hero2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-cards-container {
    grid-gap: var(--dl-space-space-unit);
  }
  .home-text33 {
    max-width: 770px;
  }
  .home-gallery {
    flex-direction: column;
  }
  .home-top {
    flex-direction: column;
  }
  .home-bottom {
    flex-direction: column;
  }
  .home-full-width-banner {
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-left5 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-content {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text40 {
    width: auto;
  }
  .home-image7 {
    width: 100%;
  }
  .home-footer2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .home-container10 {
    padding-top: 55px;
  }
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-hero2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container13 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-left5 {
    width: auto;
  }
  .home-footer2 {
    padding: var(--dl-space-space-unit);
  }
  .home-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-container20 {
    margin-right: var(--dl-space-space-unit);
  }
  .home-container21 {
    margin-right: var(--dl-space-space-unit);
  }
}
/* Popup styles */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.about{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0px;
  padding-left: 50px;
  padding-right: 108px;
  text-decoration: underline;
}
.popup-content {
  background: rgb(255, 255, 255);
  padding: 30px; /* Increase padding for better spacing */
  border-radius: 12px; /* Slightly round corners */
  width: 90%;
  max-width: 800px; /* Maximum width for larger screens */
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.3); /* Deeper shadow for depth */
  text-align: center; /* Center-align text */
  display: flex; /* Use flex for image and text layout */
  flex-direction: column; /* Stack elements vertically */
}

.popup-inner {
  display: flex;
  align-items: center; /* Center items vertically */
  flex-direction: row; /* Side by side on larger screens */
}

.popup-image {
  width: 200px; /* Fixed width for the image */
  height: auto; /* Maintain aspect ratio */
  margin-right: 20px; /* Space between image and text */
}

.popup-text {
  flex: 1; /* Allow text to take remaining space */
  text-align: left; /* Left-align text for readability */
}

.close-btn {
  margin-top: 20px;
  margin-left: 175px;
  padding: 12px 24px; /* Button padding */
  background: #555; /* Button background color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s; /* Smooth transition */
  width: 100%; /* Make button full width */
  max-width: 100px; /* Limit max width */
  align-self: center; /* Center the button */
}

.close-btn:hover {
  background: #333; /* Darker on hover */
}
.popup-links {
  margin: 20px 0; /* Space above and below the links */
}

.popup-link {
  margin-left: 100px;
  /* margin: 0 10px; Space between links */
  color: #555; /* Link color */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Make links bold */
  transition: color 0.3s; /* Smooth transition for hover */
}

.popup-link:hover {
  color: #333; /* Darker color on hover */
}

.icon-links2 {
  display: flex;                /* Use flexbox */
  justify-content: space-between; /* Distribute space between icons */
  gap: 30px;
  margin-top: 80px;
  margin-left: 55px;

}

.iconlink2 {
  text-decoration: none;        /* Remove underline from links */
  color: inherit;              /* Inherit color from parent */
}

.iconlink2 i {
  font-size: 24px;             /* Adjust icon size if needed */
}
/* Responsive adjustments */
@media (max-width: 479px) {
  .popup-inner {
    height: fit-content; /* Allow height to adjust based on content */
    max-height: fit-content; /* Ensure maximum height is based on content */
    flex-direction: column; /* Stack image and text on smaller screens */
    align-items: center; /* Center items horizontally */
    padding: 10px; /* Add some padding for better spacing */
  }
  
  .popup-image {
    width: 100%; /* Increased size for better visibility */
    height: 380px; /* Adjust height for consistency */
    margin-right: 0; /* Remove right margin on mobile */
    margin-bottom: 15px; /* Space below the image */
  }

  .popup-content {
    width: 95%; /* Full width on mobile */
    max-width: none; /* Allow full width on small screens */
    height: fit-content; /* Allow content height to adjust */
    padding: 15px; /* Add padding for better spacing */
  }

  .close-btn {
    margin-left: 33%; /* Center button and add vertical spacing */
    width: 100%; /* Make button full width for better accessibility */
    max-width: 100px; /* Limit max width for larger screens */
  }

  .popup-link {
    margin-left: 70px;/* Vertical spacing for links */
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0; /* Remove left margin for small screens */
    padding: 0 10px; /* Add padding for better spacing */
  }
}



.ps{
  display: flex;               /* Use Flexbox for centering */
  justify-content: center;     /* Center horizontally */
  align-items: center;         /* Center vertically */
  min-height: 100vh;          /* Take at least full viewport height */
  padding: 20px;  
}



/* Responsive adjustments */
@media (max-width: 479px) {
  .ps{display: flex;               /* Use Flexbox for centering */
  justify-content: center;     /* Center horizontally */
  align-items: center;         /* Center vertically */
  min-height: 100vh;          /* Take at least full viewport height */
  padding: 20px;}  
}




/*Change must be done for screen sizes */
/* General styling for desktop layout */
.home-navbar2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  width: 100%;
}

/* Mobile view */
@media screen and (max-width: 768px) {
  /* Hide desktop navbar and show mobile menu */
  .home-navbar2 {
    display: none;
  }

  .home-burger-menu {
    display: block; /* Show burger menu on mobile */
  }

  .home-mobile-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
    padding: 20px;
  }

  .home-middle1 {
    display: none; /* Hide middle section on mobile */
  }

  .home-middle2 {
    display: flex; /* Show alternate mobile middle section if necessary */
  }
}

/* Larger screens adjustments */
@media screen and (min-width: 768px) {
  .home-navbar2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .home-burger-menu,
  .home-mobile-menu {
    display: none; /* Hide burger menu and mobile menu on larger screens */
  }

  .home-middle1 {
    display: flex; /* Ensure full navigation visibility on larger screens */
  }
  
  .home-middle2 {
    display: none; /* Hide mobile-specific middle section on larger screens */
  }
}
