.blog-post-card-blog-post-card {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 400px;
  align-self: stretch;
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
}

.blog-post-card-blog-post-card:hover {
  transform: scale(1.02);
}

.blog-post-card-image {
  flex: 1;
  width: 100%;
  object-fit: cover;
}

.blog-post-card-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}

.blog-post-card-text1 {
  color: var(--dl-color-gray-black80);
  font-size: 22px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  line-height: 28px;
  margin-bottom: var(--dl-space-space-unit);
}

.blog-post-card-text2 {
  color: var(--dl-color-gray-black50);
  font-size: 16px;
  font-style: normal;
  margin-bottom: var(--dl-space-space-twounits);
}

.button {
  display: inline-block; /* Ensure it behaves like a button */
  background-color: #555555; /* Primary color for the button */
  color: white; /* Text color */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Make the font bold */
  padding: 10px 20px; /* Vertical and horizontal padding */
  border: none; /* Remove border */
  border-radius: 15px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions for hover effects */
  cursor: pointer; /* Pointer cursor on hover */
  margin-top: 10px; /* Space above the button */
}

/* Hover effect */
.button:hover {
  background-color: #9b9ea1b9; /* Darker shade for hover effect */
  transform: scale(1.05); /* Slightly scale the button on hover */
}

@media(max-width: 991px) {
  .blog-post-card-blog-post-card {
    max-width: 48%;
  }
}

@media(max-width: 767px) {
  .blog-post-card-blog-post-card {
    max-width: 100%;
  }
}
